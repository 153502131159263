<template>
    <div class="auth-container auth-bg-container">
        <video playsinline autoplay muted loop>
            <source src="@/assets/videos/Sequence_22.mp4" type="video/webm">
            Your browser does not support the video tag.
        </video>
        <div class="h-100">
            <div class="row h-100 m-0 authRowContainer">
                <div class="col-xl-4 col-lg-6 col-md-9 col-sm-12 mx-auto my-auto">
                    <div class="login">
                        <b-container class="p-0 pm-2">
                            <b-card class="card-login pb-4">
                                <router-link to="/" >
                                    <img src="@/assets/images/logo.png" class="d-block mx-auto" />
                                </router-link>

                                <b-row class="mt-3">
                                    <b-col>
                                        <h6 class="welecomeH6 mt-3">
                                            Welcome
                                        </h6>
                                        <p class="welcomeP">
                                            Please sign-in to your studio account
                                        </p>
                                    </b-col>
                                </b-row>
                                <b-row><b-col>
                                        <!-- -->
                                        <b-alert v-model="showLoginErrorMessage" variant="danger">{{ loginErrorMessage }}
                                        </b-alert>
                                    </b-col></b-row>
                                <b-form @submit.prevent="onSubmit" class="mt-2">
                                    <b-row class="mt-1">
                                        <b-col>
                                            <b-form-group id="email-input-group" label="Email" label-for="email">
                                                <b-form-input class="form-control-auth" id="email" v-model="form.email"
                                                    type="email" placeholder="Enter email" autofocus @keydown.space.prevent
                                                    required></b-form-input>
                                            </b-form-group></b-col></b-row>
                                    <b-row class="mt-4">
                                        <b-col>
                                            <b-form-group id="password-input-group" label="Password" label-for="password">
                                                <!-- <b-form-input class="form-control-auth" id="password"
                                                    v-model="form.password" type="password" placeholder="Enter password"
                                                    required></b-form-input> -->
                                                <b-input-group class="input-group-password">
                                                    <b-form-input class="form-control-validate-password form-control-auth"
                                                        id="password" v-model="form.password"
                                                        :type="show_password ? 'text' : 'password'"
                                                        placeholder="Enter password" required></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button @click="show_password = !show_password"><font-awesome-icon
                                                                icon="fa-solid fa-eye" v-if="!show_password" />
                                                            <font-awesome-icon icon="fa-solid fa-eye-slash"
                                                                v-else /></b-button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <div class="row forgetPasswordRowDiv mt-2">
                                        <div class="col-md-6 mt-1 mb-md-4 mb-0 pl-0">
                                            <div class="form-check">
                                                <label class="form-check-label" for="remember-me">
                                                    <input type="checkbox" class="form-check-input" id="remember-me"
                                                        name="remember" />
                                                    Remember me
                                                    <span class="form-check-sign">
                                                        <span class="check"></span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2 mb-md-4 mb-2 pr-0">
                                            <router-link class="float-right forgetPasswordA" to="/forget_password">
                                                Forget password?
                                            </router-link>
                                        </div>
                                    </div>
                                    <input type="hidden" name="guard" v-model="guard" />
                                    <div class="d-flex justify-content-center align-content-center submitButtonContainer">
                                        <button class="btn btn-login btn-default w-100" type="submit">
                                            Login
                                        </button>
                                    </div>
                                    <div class="d-flex justify-content-center align-content-center mt-4">
                                        <p class="rememberPasswordP">
                                            New to our platform?
                                            <router-link to="" class="forgetPasswordA">Create an account
                                            </router-link>
                                        </p>
                                    </div>
                                </b-form>
                            </b-card>
                        </b-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    name: "ProtostarsLogin",
    components: {},
    data() {
        return {
            form: {
                email: "",
                password: "",
                guard: this.guard,
            },
            showLoginErrorMessage: false,
            loginErrorMessage: "",
            show_password: false,
        };
    },
    mounted() { },
    methods: {
        onSubmit() {
            console.log("submit login");
        },
    },
};
</script>