<template>
  <section>
    <!-- <img src="@/assets/images/stellar/cta-bg.svg" class="max-w-none w-[360px] h-[360px]"
    alt="Hero Illustration"> -->
    <div class="relative xl:max-w-6xl lg:max-w-4xl md:max-w-[98%] sm:max-w-[85%] max-w-[95%] w-full mx-auto  py-9 md:py-19">
      <!-- <div class="relative px-8 py-12 md:py-20 rounded-[3rem] overflow-hidden"> -->
      <!-- Radial gradient -->
      <!-- <div class="absolute flex items-center justify-center top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square" aria-hidden="true">
          <div class="absolute inset-0 translate-z-0 bg-cyan-500 rounded-full blur-[120px] opacity-70"></div>
          <div class="absolute w-1/4 h-1/4 translate-z-0 bg-cyan-400 rounded-full blur-[40px]"></div>
        </div> -->
      <!-- Blurred shape -->
      <!-- <div class="absolute bottom-0 translate-y-1/2 left-0 blur-2xl opacity-50 pointer-events-none -z-10" aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
            <defs>
              <linearGradient id="bs5-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                <stop offset="0%" stop-color="#55B3F7" />
                <stop offset="100%" stop-color="#6366F1" stop-opacity="0" />
              </linearGradient>
            </defs>
            <path fill="url(#bs5-a)" fill-rule="evenodd" d="m0 0 461 369-284 58z" transform="matrix(1 0 0 -1 0 427)" />
          </svg>
        </div> -->

      <div class="absolute inset-0 -z-10 lg:-mx-20 xl:-mx-28 mx-0 rounded-3xl pointer-events-none overflow-hidden lg:block hidden" aria-hidden="true">
        <div class="absolute left-1 inset-1  top-1 -z-10">
          <img src="@/assets/images/stellar/cta-bg.svg" class="lg:max-w-none  w-full h-full " alt="Hero Illustration">
        </div>
      </div>
      <!-- Content -->
      <div class="lg:max-w-3xl max-w-full mx-auto text-center lg:px-0 px-3 lg:py-0 py-6 lg:border-none border border-[#55DAF7D1] lg:rounded-none rounded-xl">
        <div>
          <div
            class="inline-flex text-base/normal font-medium bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 to-green-300 mb-3">
            {{ $t('cta_subtitle') }}</div>
        </div>
        <h2
          class=" lg:text-5xl/snug md:text-2xl/normal text-lg font-extrabold bg-clip-text capitalize text-transparent bg-gradient-to-r from-slate-50/65 via-slate-50 to-slate-50/65 mb-8">
          {{ $t('cta_title') }}</h2>
        <p class="text-lg/7 text-slate-400 mb-8">{{ $t('cta_text') }}</p>
        <div
          class="relative bg-gradient-to-br from-[#55DAF7] via-[#99D5FF] to-black/0 rounded-full shadow-lg p-0.5 h-10 w-auto max-w-fit mx-auto mb-5">
          <a class="btn rounded-full h-full w-full text-slate-300 text-base/normal font-medium bg-slate-800 transition duration-150 ease-in-out group"
            href="/early-invite">
            {{ $t("early_wishlist_title") }} <span
              class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2 my-auto"><font-awesome-icon
                icon="fa-solid fa-arrow-right" /> </span>
          </a>
        </div>
        <!-- <div
          class="relative bg-gradient-to-br from-[#55DAF7] via-[#99D5FF] to-black/0 rounded-full shadow-lg p-0.5 h-10 w-auto max-w-52 mx-auto mb-5">
          <a class="btn rounded-full h-full w-full text-slate-300 text-base/normal font-medium bg-slate-800 transition duration-150 ease-in-out group"
            href="/cyber-grants">
            {{ $t("btn_cyber_grants") }} <span
              class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2 my-auto"><font-awesome-icon
                icon="fa-solid fa-arrow-right" /> </span>
          </a>
        </div> -->
        
      </div>
      <!-- </div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: 'CtaSection',
}
</script>