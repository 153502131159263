<template>
    <section>
        <div class="relative max-w-6xl mx-auto  md:px-14  px-2 py-12 md:py-20">
            <!-- Illustration -->
            <div class="absolute inset-0 -z-10 -mx-28 rounded-t-[3rem] pointer-events-none overflow-hidden"
                aria-hidden="true">
                <div class="absolute left-1/2 -translate-x-1/2 top-0 -z-10">
                    <img src="@/assets/images/stellar/glow-top-blue.svg" class="max-w-none" width="1404" height="658"
                        alt="Features Illustration">
                </div>
            </div>

            
            <div class="w-full mx-auto mb-6 text-center">
                <h2 class="xl:text-[50px] md:text-2xl/normal text-xl leading-tight text-slate-50 font-extrabold">{{
                    $t('cyber_negligence_title_1') }}
                </h2>
                <!-- <h2 class="text-5xl/normal text-slate-50 font-extrabold">{{ $t('cyber_negligence_title_2') }}</h2> -->
                <p class="md:text-base/normal text-sm/normal text-[#55d9f7] font-medium my-4">{{
                    $t('cyber_negligence_subtitle') }}</p>
            </div>
            <div class="w-full mx-auto ">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7">
                    <div v-for="(item, index) in $tm('cyber_negligence_text')" :key="index"
                        class="border border-slate-800 rounded-3xl py-9 px-3 text-center flex flex-col group hover:bg-[url('@/assets/images/stellar/feature-bg-2.svg')] hover:bg-cover hover:border-transparent">
                        <img :src="getImg(item.img)" class="h-48 max-h-48 max-w-[75%] mx-auto mb-8" />

                        <h3 class="text-lg/normal text-slate-200 font-bold mb-4 -mx-2 "> {{ item.title }}</h3>

                        <p class="text-base/snug text-slate-400 font-normal mb-4  group-hover:text-[#eaeaea]"><span
                                class="font-semibold">{{ item.subtitle }}</span> {{ item.text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    name: 'FeaturesCyber',
    data() {
        return {
            // Your component data goes here
        };
    },
    methods: {
        // Your component methods go here
        getImg(img) {
            return require(`@/assets/images/${img}`);
        }
    },
    computed: {
        // Your computed properties go here
    },
    watch: {
        // Your watchers go here
    },
    mounted() {
        // Lifecycle hook for when the component is mounted
    }
};
</script>

<style scoped>
/* Your component styles go here */
</style>