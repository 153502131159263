<template>
  <div className="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip">
    <!-- Site header -->
    <HeaderSection />

    <!-- Page content -->
    <main class="grow">
      <TalkToUsHero>
        <section class="relative">
          <div class="container mx-auto min-h-52 lg:flex lg:flex-col block lg:py-12 py-3 md:px-4 px-px">

            <!-- Page header -->
            <div class="text-center mb-6">
              <div
                class="inline-flex text-base/normal font-medium bg-clip-text capitalize text-transparent bg-gradient-to-r from-cyan-500 to-[#62FBFE45] mb-4">
                {{ $t('cyber_grant_page_text') }}
              </div>
              <h1
                class="font-inter xl:text-6xl/normal md:text-4xl/normal text-2xl font-bold capitalize from-slate-50/60 via-slate-50 to-slate-50/60 mb-4 bg-clip-text text-transparent bg-gradient-to-r ">
                {{ $t('cyber_grant_page_title') }} </h1>
              <p class="md:text-lg/7 text-base font-normal text-slate-400">
                {{ $t('cyber_grant_page_subtitle') }} </p>
            </div>

            <div class="grid gap-6 sm:grid-cols-2 lg:grid-cols-4 max-md:mx-2 mb-12">
              <div
                class="relative h-full bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-purple-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden">
                <div class="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                  <!-- Particles animation -->
                  <!-- <Particles class="absolute inset-0 -z-10" :quantity="3" /> -->
                  <div class="flex items-center justify-center">
                    <img class="w-full h-full aspect-video object-cover" src="@/assets/images/request-call/bg-1.svg"
                      width="352" height="198" alt="Customer Background" aria-hidden="true" />
                    <img class="absolute w-7/12" src="@/assets/images/request-call/enterprise-ireland-logo.png"
                      alt="enterprise-ireland" />
                  </div>
                </div>
              </div>
              <!---->
              <div
                class="relative h-full bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-purple-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden">
                <div class="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                  <!-- Particles animation -->
                  <!-- <Particles class="absolute inset-0 -z-10" :quantity="3" /> -->
                  <div class="flex items-center justify-center">
                    <img class="w-full h-full aspect-video object-cover" src="@/assets/images/request-call/bg-1.svg"
                      width="352" height="198" alt="Customer Background" aria-hidden="true" />
                    <img class="absolute w-1/3" src="@/assets/images/request-call/ncsc-logo.png" alt="ncsc" />
                  </div>
                </div>
              </div>
              <!---->
              <div
                class="relative h-full bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-purple-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden">
                <div class="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                  <!-- Particles animation -->
                  <!-- <Particles class="absolute inset-0 -z-10" :quantity="3" /> -->
                  <div class="flex items-center justify-center">
                    <img class="w-full h-full aspect-video object-cover" src="@/assets/images/request-call/bg-1.svg"
                      width="352" height="198" alt="Customer Background" aria-hidden="true" />
                    <img class="absolute w-7/12" src="@/assets/images/request-call/ncc-logo.png" alt="ncc" />
                  </div>
                </div>
              </div>
              <!---->
              <div
                class="relative h-full bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-purple-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden">
                <div class="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                  <!-- Particles animation -->
                  <!-- <Particles class="absolute inset-0 -z-10" :quantity="3" /> -->
                  <div class="flex items-center justify-center">
                    <img class="w-full h-full aspect-video object-cover" src="@/assets/images/request-call/bg-1.svg"
                      width="352" height="198" alt="Customer Background" aria-hidden="true" />
                    <img class="absolute w-8/12" src="@/assets/images/request-call/cyber-ireland-logo.png"
                      alt="cyber-ireland" />
                  </div>
                </div>
              </div>
            </div>

            <!--Phases-->
            <div class="max-w-7xl mx-auto md:px-4 px-2">
              <div class="py-4">

                <div class="max-w-5xl mx-auto">
                  <div class="relative">
                    <div
                      class="absolute h-full top-4  w-0.5 bg-slate-800 [mask-image:_linear-gradient(0deg,transparent,theme(colors.white)_150px,theme(colors.white))] -z-10 overflow-hidden after:absolute after:h-4 after:top-0 after:-translate-y-full after:left-0 after:w-0.5 after:bg-[linear-gradient(180deg,_transparent,_#77D8FB_9.4%,_#99D5FF_90.4%,_transparent)] after:animate-shine"
                      aria-hidden="true"></div>
                    <!-- Phase 1 -->
                    <article class="pt-6 first-of-type:pt-0 group">
                      <div class="flex">
                        <div class="md:w-14 w-8 shrink-0">

                          <time
                            class="text-sm inline-flex items-center bg-clip-text text-white bg-gradient-to-r from-[#77D8FB] to-[#99D5FF]  -ml-0.5 md:leading-8 before:w-1.5 before:h-1.5 before:rounded-full before:bg-[#77D8FB] before:ring-4 before:ring-[#77D8FB]/30 mb-3">
                          </time>
                        </div>
                        <div
                          class="grow ml-0  pb-12 group-last-of-type:pb-0 border-b [border-image:linear-gradient(to_right,theme(colors.slate.700/.3),theme(colors.slate.700),theme(colors.slate.700/.3))1] group-last-of-type:border-none">
                          <header>
                            <h1
                              class="text-[32px] font-inter font-bold leading-[44px]  bg-clip-text text-white bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-6">
                              {{ $t('request_call_back_title_phase1') }}</h1>
                            <h4
                              class="text-[28px] leading-9 font-inter font-normal bg-clip-text text-white bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60  mb-8">
                              {{ $t('request_call_back_text_phase1') }}</h4>
                          </header>
                          <div class="space-y-3 w-full text-white mb-4">
                            <ul class=" text-white font-normal list-disc ms-5">
                              <li class="md:text-lg/7 text-base font-normal font-inter mb-1 ">
                                {{ $t('list_request_call_back_1_phase1') }}</li>
                              <li class="md:text-lg/7 text-base font-normal font-inter mb-1">
                                {{ $t('list_request_call_back_2_phase1') }}</li>
                              <li class="md:text-lg/7 text-base font-normal font-inter mb-1">
                                {{ $t('list_request_call_back_3_phase1') }}</li>
                            </ul>
                            <!-- <div class="flex items-start space-x-3 w-full">
                              <div class="flex-shrink-0 pt-1">
                                <div class="w-2 h-2 rounded-full bg-[#fff]"></div>
                              </div>
                              <p class="flex-1">{{$t('list_request_call_back_1_phase1')}}</p>
                            </div>

                            <div class="flex items-start space-x-3 w-full">
                              <div class="flex-shrink-0 pt-1">
                                <div class="w-2 h-2 rounded-full bg-[#77D8FB]"></div>
                              </div>
                              <p class="flex-1">{{$t('list_request_call_back_2_phase1')}}</p>
                            </div>

                            <div class="flex items-start space-x-3 w-full">
                              <div class="flex-shrink-0 pt-1">
                                <div class="w-2 h-2 rounded-full bg-[#77D8FB]"></div>
                              </div>
                              <p class="flex-1">{{ $t('list_request_call_back_2_phase1') }}</p>
                            </div> -->
                          </div>
                          <div class="mb-14 ">
                            <a :href="file_path" download="Protostars Cyber Grant Services.pdf"
                              class="flex items-center gap-1.5">
                              <img src="@/assets/images/download-icon.png" class="inline-block h-6" />
                              <p class="inline-block text-cyan-300 text-base/6 font-medium ">
                                {{ $t("download_brochure_btn") }}
                              </p>
                            </a>
                          </div>

                          <div class="flex max-lg:flex-wrap  gap-10">
                            <!-- Left column - Image -->
                            <div class="lg:w-3/5 w-full h-full flex items-center  justify-center ">
                              <img src="@/assets/images/request-call/Cyber-Grant-Services-Cover-page%201.png"
                                class=" rounded-lg w-full h-full" />
                            </div>
                            <!-- Right column - Form -->
                            <div class="lg:w-2/5 w-full h-full flex flex-col justify-center">
                              <h2 class="text-2xl/snug font-medium font-inter bg-clip-text text-white mb-2">
                                {{ $t("free_intro_call_title") }}
                              </h2>

                              <p class="text-lg/6 font-normal text-slate-300  font-inter mb-4 ">
                                {{ $t("free_intro_call_text") }}
                              </p>


                              <Vueform v-model="formData" validate-on="change|step" class="vueFormContainer"
                                :endpoint="false" ref="talkToUsFormRef"
                                @submit="submitTalkToUs($event, 'Cyber Review Grant')" :display-messages="false"
                                :display-errors="false">
                                <TextElement name="name" :placeholder="$t('label_name')" rules="required"
                                  class="" />

                                <TextElement name="email" input-type="email" :placeholder="$t('label_email')"
                                  rules="required|email" class="" />

                                <TextElement name="company_name" :placeholder="$t('label_company_name')"
                                  rules="required" class="" />

                                <CheckboxElement name="newsletter_subscribe" :true-value="1" :false-value="0"
                                  class="mb-2">
                                  {{ $t('label_receive_updates') }}
                                </CheckboxElement>

                                <ButtonElement name="button" :submits="true" full :loading="is_loading"
                                  button-class="bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg text-center !border-gray-100 !text-gray-800 text-md font-bold leading-normal ">
                                  {{ $t("btn_submit_request") }}
                                </ButtonElement>
                              </Vueform>
                            </div>
                          </div>

                        </div>
                      </div>
                    </article>
                    <!-- Phase 2 -->
                    <article class="pt-6 first-of-type:pt-0 group">
                      <div class="flex">
                        <div class="md:w-14 w-8 shrink-0">
                          <time
                            class="text-sm inline-flex items-center bg-clip-text text-white bg-gradient-to-r from-[#77D8FB] to-[#99D5FF]  -ml-0.5 md:leading-8 before:w-1.5 before:h-1.5 before:rounded-full before:bg-[#77D8FB] before:ring-4 before:ring-[#77D8FB]/30 mb-3">
                          </time>
                        </div>
                        <div
                          class="grow ml-0  pb-12 group-last-of-type:pb-0 border-b [border-image:linear-gradient(to_right,theme(colors.slate.700/.3),theme(colors.slate.700),theme(colors.slate.700/.3))1] group-last-of-type:border-none">

                          <header>
                            <h1
                              class="text-[32px] font-bold leading-[44px]  font-inter  bg-clip-text text-white bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 mb-4">
                              {{ $t('request_call_back_title_phase2') }}</h1>

                            <h4
                              class="text-[28px] leading-[44px] font-normal  font-inter bg-clip-text text-white bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60  mb-5">
                              {{ $t('request_call_back_subtitle_phase2') }}</h4>
                          </header>
                          <div class="space-y-3 w-full text-white mb-8">

                            <p class="font-inter font-normal text-lg/7 text-white"
                              v-html="$t('request_call_back_text_phase2')"></p>

                          </div>

                          <div class="flex max-md:flex-col flex-row items-center justify-center md:space-x-4">
                            <!-- Card 1 -->
                            <div class="lg:w-[30%] md:w-[27%] w-full max-w-72 mb-3 mx-auto">
                              <img src="@/assets/images/request-call/step-1.png" alt="Card 1"
                                class="w-full h-full object-cover ">
                            </div>

                            <!-- Arrow from Card 1 to Card 2 -->
                            <div class="lg:w-8 lg:h-8 md:w-6 md:h-6 hidden md:flex  items-center justify-center">
                              <img src="@/assets/images/request-call/arrow-right.png" alt="Arrow 1"
                                class="w-full h-full">
                            </div>

                            <!-- Card 2 -->
                            <div class="lg:w-[30%] md:w-[27%] w-full max-w-72 mb-3 mx-auto">
                              <img src="@/assets/images/request-call/step-2.png" alt="Card 2"
                                class="w-full h-full object-cover  ">
                            </div>

                            <!-- Arrow from Card 2 to Card 3 -->
                            <div class="lg:w-8 lg:h-8 md:w-6 md:h-6 hidden md:flex  items-center justify-center">
                              <img src="@/assets/images/request-call/arrow-right.png" alt="Arrow 2"
                                class="w-full h-full">
                            </div>

                            <!-- Card 3 -->
                            <div class="lg:w-[30%] md:w-[27%] w-full max-w-72  mb-3 mx-auto">
                              <img src="@/assets/images/request-call/step-3.png" alt="Card 3"
                                class="w-full h-full object-cover ">
                            </div>
                          </div>


                          <!-- Cards with Arrows -->
                          <!-- <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
                            <div class="relative flex items-center lg:flex-row flex-col">
                              <div class=" bg-center bg-contain bg-no-repeat p-6 flex flex-col items-center w-full">
                                <img src="@/assets/images/request-call/step-1.png" alt="enterprise-ireland" />
                              </div>
                              <div
                                class="hidden lg:flex items-center justify-center w-16 self-center absolute -right-10">
                                <img src="@/assets/images/request-call/arrow-right.png" class="w-10" alt="arrow" />
                              </div>
                            </div>

                            <div class="relative flex items-center lg:flex-row flex-col">
                              <div class=" bg-center bg-contain bg-no-repeat p-6 flex flex-col items-center w-full">
                                <img src="@/assets/images/request-call/step-2.png" alt="enterprise-ireland" />
                              </div>
                              <div
                                class="hidden lg:flex items-center justify-center w-16 self-center absolute -right-10">
                                <img src="@/assets/images/request-call/arrow-right.png" class="w-10" alt="arrow" />
                              </div>
                            </div>

                            <div class="relative flex items-center lg:flex-row flex-col">
                              <div class=" bg-center bg-contain bg-no-repeat p-6 flex flex-col items-center w-full">
                                <img src="@/assets/images/request-call/step-3.png" alt="enterprise-ireland" />
                              </div>
                            </div>

                          </div> -->


                        </div>
                      </div>
                    </article>


                    <!-- Phase 3 -->
                    <article class="pt-6 first-of-type:pt-0 group">
                      <div class="flex">
                        <div class="md:w-14 w-8 shrink-0">
                          <time
                            class="text-sm inline-flex items-center bg-clip-text text-white bg-gradient-to-r from-[#77D8FB] to-[#99D5FF] -ml-0.5 md:leading-8 before:w-1.5 before:h-1.5 before:rounded-full before:bg-[#77D8FB] before:ring-4 before:ring-[#77D8FB]/30 mb-3">
                          </time>
                        </div>
                        <div
                          class="grow ml-0  pb-12 group-last-of-type:pb-0 border-b [border-image:linear-gradient(to_right,theme(colors.slate.700/.3),theme(colors.slate.700),theme(colors.slate.700/.3))1] group-last-of-type:border-none">

                          <header>
                            <h1
                              class="text-[32px] font-bold leading-[44px]  font-inter  bg-clip-text text-white bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 mb-4">
                              {{ $t('request_call_back_title_phase3') }}</h1>

                            <h4
                              class="text-[28px] leading-[44px] font-normal  font-inter bg-clip-text text-white bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60  mb-5">
                              {{ $t('request_call_back_subtitle_phase3') }}</h4>
                          </header>
                          <div class="space-y-3 w-full text-white mb-10">

                            <p class="font-inter font-normal text-lg/7 text-white"
                              v-html="$t('request_call_back_text_phase3')">
                            </p>

                          </div>

                          <div class="flex max-lg:flex-wrap  gap-10">
                            <!-- Left column - Image -->
                            <div class="lg:w-3/5 w-full h-full flex items-center  justify-center ">
                              <img src="@/assets/images/request-call/protostars-compliance.png"
                                class=" rounded-lg w-full h-full" />
                            </div>
                            <!-- Right column - Form -->
                            <div class="lg:w-2/5 w-full h-full flex flex-col justify-center">
                              <h2 class="text-2xl/snug font-medium bg-clip-text text-white mb-2">
                                {{ $t('join_waitlist_title') }}
                              </h2>

                              <p class="text-lg/6 font-normal text-slate-300 mb-4">
                                {{ $t('join_waitlist_text') }}
                              </p>


                              <Vueform v-model="formData" validate-on="change|step" class="vueFormContainer"
                                :endpoint="false" ref="talkToUsFormRef"
                                @submit="submitTalkToUs($event, 'Early Adopter Waitlist')" :display-messages="false"
                                :display-errors="false">
                                <TextElement name="name" :placeholder="$t('label_name')" rules="required">
                                </TextElement>

                                <TextElement name="email" input-type="email" :placeholder="$t('label_email')"
                                  rules="required|email">
                                </TextElement>

                                <TextElement name="company_name" :placeholder="$t('label_company_name')"
                                  rules="required">
                                </TextElement>

                                <SelectElement name="company_size" :items="company_size_options" :native="false"
                                  :search="false" :placeholder="$t('label_company_size')" rules="required" />

                                <CheckboxElement name="newsletter_subscribe" :true-value="1" :false-value="0">
                                  {{ $t('label_receive_updates') }}
                                </CheckboxElement>

                                <ButtonElement name="button" :submits="true" full :loading="is_loading"
                                  button-class="bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg text-center !border-gray-100 !text-gray-800 text-md font-bold leading-normal ">
                                  {{ $t("btn_join_now") }}
                                </ButtonElement>

                              </Vueform>

                            </div>
                          </div>

                        </div>
                      </div>
                    </article>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </section>
      </TalkToUsHero>

    </main>
    <FooterSection />
  </div>
</template>

<script>

import HeaderSection from '@/partials/HeaderSection.vue'
import TalkToUsHero from '@/partials/TalkToUsHero.vue'
import FooterSection from '@/partials/FooterSection.vue'

import axios from 'axios';

export default {
  name: 'RequestCallBack',
  components: {
    HeaderSection,
    TalkToUsHero,
    FooterSection
  },
  data() {
    return {
      formData: null,

      is_loading: false,


      company_size_options: [
        // 250 - 500, 500 - 1000, 1000 - 2,500 then 2,500+
        { label: '1-250 employees', value: '1-250' },
        { label: '250-500 employees', value: '250-500' },
        { label: '500-1000 employees', value: '500-1000' },
        { label: '1000-2500 employees', value: '1000-2500' },
        { label: '2500+ employees', value: '2500+' }
      ],

      file_path: new URL('@/assets/pdf/Protostars Cyber Grant Services.pdf', import.meta.url).href

    }
  },
  mounted() {
  },
  methods: {
    submitTalkToUs(e, lead_form) {
      console.log("in submit talk to us")
      console.log(e)
      console.log(this.formData)
      console.log(this.$refs.talkToUsFormRef)
      // e.preventDefault()
      this.is_loading = true

      let formDataRequest = e.data

      formDataRequest.lead_form = lead_form
      console.log("formDataRequest", formDataRequest)
      // return;
      axios
        .post("early-program/register-client", formDataRequest, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            client: "PROTOSTARS",
          },
        })
        .then((response) => {
          console.log(response);
          this.$swal({
            // title: "Title",
            text: lead_form == 'Cyber Review Grant' ? this.$t('grant_success_message') : this.$t('talk_to_us_success_message'),
            icon: "success",
            confirmButtonColor: '#55B3F7',
            customClass: {
              confirmButton: "bg-gray-100  rounded-lg text-center !border-gray-100 !text-gray-800 text-md font-medium leading-normal "
            },
            color: "#F3F4F6",

            background: "#374151"
            // timer: 3000,
          }).then((r) => {
            // console.log(r.value);
            // this.formData = {}
            this.$refs.talkToUsFormRef.reset()
            this.is_loading = false
            this.$router.push('/')
          });


        })
        .catch((error) => {
          // console.log(error);
          this.$swal({
            // title: "Title",
            text: error.response.data.error
              ? error.response.data.error
              : error.response.data.message,
            icon: "error",
            confirmButtonColor: '#03989E',
            customClass: {
              confirmButton: "btn btn-primary "
            },
            // timer: 3000,
          }).then((r) => {
            // console.log(r.value);
            this.is_loading = false
          });
        });

    }
  }

}
</script>

<style scoped>
.card {
  display: flex;
  align-items: center;
  border-radius: 0.75rem;
  /* 12px */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
</style>
