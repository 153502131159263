<template>
  <!-- <div v-if="!$route.fullPath.includes('login')">
    <Navbar />
    <div class="home-page">

      <div class="content">

        <router-view :key="$route.fullPath" />
      </div>
    </div>
    <Footer />
  </div>
  <div v-else>

    <router-view :key="$route.fullPath" />
  </div> -->
  <router-view :key="$route.fullPath" />

    <VueCookieComply :preferences="preferences" @on-accept-all="onAccept" @on-decline-all="onDecline"
      @on-save-preferences="onSavePreferences" bannerMaxWidth="100%"
      :bannerDescription="'We use cookies and similar technologies to help personalise content and offer a better experience. You can opt to customise them by clicking the preferences button.'"
      ></VueCookieComply>

  <!-- <vue-cookie-comply :preferences="preferences" @on-accept-all-cookies="onAccept"
    @on-save-cookie-preferences="onSavePreferences" /> -->
</template>

<script>

// import Navbar from '@/components/layout/Navbar.vue';
// import HomeLandPage from './views/HomeLandPage.vue'
// import Footer from './components/layout/Footer.vue';
import $ from 'jquery'

import AOS from 'aos'

export default {
  name: 'App',
  components: {
    // Navbar, Footer
  },
  data() {
    return {
      preferences: [
        {
          title: 'Session token (required)',
          description: 'This is a performance cookie. It is used to maintain your current session while using the website.',
          items: [
            {
              label: '*_session',
              value: 'session',
              isRequired: true,
            },
          ],
        },
        {
          title: 'XSRF Token (required)',
          description: 'This is a performance cookie. It is used to prevent third parties from submitting forms on the website on your behalf.',
          items: [
            {
              label: 'XSRF-TOKEN',
              value: 'xsrf_token',
              isRequired: true,
            },
          ],
        },
      ]

    }
  },
  mounted() {
    AOS.init({
      once: true,
      duration: 500,
      easing: 'ease-out-cubic',
    })
  },
  updated() {
    console.log("app ", this.$route)
    if (this.$route.hash) {
      $('html, body').animate({
        scrollTop: $(this.$route.hash).offset().top - 90
      });
    } else {
      $('html, body').animate({
        scrollTop: 0
      });
    }
  }
}
</script>

<style>
@import './../node_modules/@vueform/vueform/dist/tailwind-material.css';

/* .cookie-comply {
  z-index: 9999;
}

.cookie-comply__header-title {
  color: #212121;
}

.cookie-comply__header-description {
  color: #3a3a3a
}

.cookie-comply__modal {
  z-index: 9999;
  color: #212121;
}

.cookie-comply__button {
  color: #333;
}
.cookie-comply__modal h2{
  font-size: 20px;

} */
 aside{
  z-index: 10000;
 }
 aside main{
  /* margin-top: 3rem; */
  max-height: calc(100vh - 190px);
  margin: auto;
 }
</style>
