<template>
  <section>
    <div class="relative ">

      <!-- Particles animation -->
      <div class="absolute inset-0 max-w-7xl mx-auto px-4 sm:px-6">
        <ParticlesComponent class="absolute inset-0 -z-10" :quantity="5" />
      </div>
      <!-- Blurred shape -->
      <div class="md:block hidden">
        <div
          class="absolute top-0 -translate-y-1/3 right-[10%] translate-x-[10%] blur-2xl opacity-50 pointer-events-none z-10"
          aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
            <defs>
              <linearGradient id="bs3-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                <stop offset="100%" stop-color="#63B5F1" />
                <stop offset="10%" stop-color="##637AF1" stop-opacity="0" />
              </linearGradient>
            </defs>
            <path fill="url(#bs3-a)" fill-rule="evenodd" d="m410 0 461 369-284 58z"
              transform="matrix(1 0 0 -1 -410 427)" />
          </svg>
        </div>
      </div>


      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="py-1 md:py-1">
          <div
            class="inline-flex text-base/normal font-medium bg-clip-text capitalize text-transparent bg-gradient-to-r from-cyan-500 to-purple-200 pb-3">
            {{ $t('trusted_by_title') }}</div>
          <!-- <div class="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 to-green-300 pb-3">{{ $t('cta_subtitle') }}</div> -->

          <div class="overflow-hidden">
            <!-- Carousel built with Swiper.js [https://swiperjs.com/] -->
            <!-- * Custom styles in src/css/additional-styles/theme.scss -->
            <div
              class="clients-carousel swiper-container relative  before:absolute before:inset-0 before:w-32 before:z-10 before:pointer-events-none before:bg-gradient-to-r before:from-slate-900  after:absolute after:inset-0 after:left-auto after:w-32 after:z-6 after:pointer-events-none after:bg-gradient-to-l after:from-slate-900">
              <div class="swiper-wrapper !ease-linear select-none items-center">
                <!-- Carousel items -->
                <div class="swiper-slide !w-auto" v-for="(client, index) in clients" :key="index">
                  <img :src="client.path" :alt="client.name" :width="client.width" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, reactive } from 'vue'
import ParticlesComponent from './ParticlesComponent.vue'

// Import Swiper
import Swiper, { Autoplay } from 'swiper'
import 'swiper/css'

Swiper.use([Autoplay])

export default {
  name: 'ClientsSection',
  components: {
    ParticlesComponent
  },
  setup() {
    const clients = reactive([
      { path: require('@/assets/images/clients/web-Station-F 1.png'), name: "station f", width: 160 },
      { path: require('@/assets/images/clients/C-Risk 1.png'), name: "c-risk", width: 80 },
      // { path: require('@/assets/images/clients/web-DirectLineGroup 1.png'), name: "direct line group", width: 140 },
      { path: require('@/assets/images/clients/web-CyberIreland 2.png'), name: "cyber ireland", width: 160 },
      { path: require('@/assets/images/clients/enterprise ireland.png'), name: "enterprise ireland", width: 190 },
      { path: require('@/assets/images/clients/web-Growing-Capital 1.png'), name: "growing capital", width: 155 },
      { path: require('@/assets/images/clients/dogpatchlabs 1.png'), name: "dogpatchlabs", width: 150 },

      // {path:require('@/assets/images/clients/Spire-logo.png'), name:"direct line group", width:140},
    ]);//
    onMounted(() => {
      const carousel = new Swiper('.clients-carousel', {
        slidesPerView: 'auto',
        spaceBetween: 64,
        centeredSlides: true,
        loop: true,
        speed: 5000,
        noSwiping: true,
        noSwipingClass: 'swiper-slide',
        autoplay: {
          delay: 0,
          disableOnInteraction: true,
        },
      })
    })

    return { clients }
  }
}
</script>