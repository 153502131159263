<template>
    <div>
        <!-- <VuePdfApp v-if="has_access" :pdf="file_path" :file-name="'protostars_presentation'" class="h-screen" /> -->

        <div v-if="has_access"  class="pdf-viewer h-screen" >
            <iframe :src="file_path" width="100%" class="h-full"  style="border: none;" ></iframe>
        </div>


        <div v-else class="h-screen">
            <div class="pt-16 pb-12 md:pt-20 md:pb-20 h-auto my-auto">
                <!--contact us form -->
                <div class="lg:w-4/12 md:w-2/4 w-11/12 mx-auto">
                    <div class="w-4/5 h-9 relative bg-gray-800 bg-opacity-60 rounded-sm mx-auto mb-3">
                        <div
                            class="w-11/12 h-7 m-auto absolute right-2 left-2 top-2 bottom-2 bg-indigo-500 bg-opacity-20 rounded-lg shadow flex justify-center p-1">
                            <p
                                class=" text-center text-indigo-500 text-sm/normal font-medium font-['Inter'] leading-snug">
                                Password</p>
                        </div>
                        <div class="w-px h-px right-1 top-1 absolute bg-gray-600 bg-opacity-60 rounded-full">
                        </div>
                        <div class="w-px h-px left-1 top-1 absolute bg-gray-600 bg-opacity-60 rounded-full">
                        </div>
                        <div class="w-px h-px right-1 bottom-1 absolute bg-gray-600 bg-opacity-60 rounded-full">
                        </div>
                        <div class="w-px h-px left-1 bottom-1 absolute bg-gray-600 bg-opacity-60 rounded-full">
                        </div>

                    </div>
                    <div class=" relative bg-gradient-to-b from-gray-700 to-gray-700/25 rounded-lg shadow  px-4 py-3">
                        <div class="w-[3px] h-[3px] left-[6px] top-[6px] absolute bg-gray-600 rounded-full"></div>
                        <div class="w-[3px] h-[3px] right-[6px] top-[6px] absolute bg-gray-600 rounded-full"></div>
                        <div class="w-[3px] h-[3px] right-[6px] bottom-[6px] absolute bg-gray-600 rounded-full"></div>
                        <div class="w-[3px] h-[3px] left-[6px] bottom-[6px] absolute bg-gray-600 rounded-full"></div>
                        <p v-if="error_message" class="text-sm text-[#ef4444] mb-2">{{ error_message }} </p>
                        <Vueform v-model="formData" validate-on="change|step" :endpoint="false" ref="contactUsFormRef"
                            :display-errors="false" @submit="checkPassword($event)">

                            <TextElement name="password" :input-type="show_password ? 'text' : 'password'"
                                :placeholder="'Password'" rules="required">
                                <template #addon-before>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="14" width="12.25"
                                        viewBox="0 0 448 512">
                                        <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
                                        <path fill="#9ca3af"
                                            d="M144 144l0 48 160 0 0-48c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192l0-48C80 64.5 144.5 0 224 0s144 64.5 144 144l0 48 16 0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 256c0-35.3 28.7-64 64-64l16 0z" />
                                    </svg>
                                </template><template #addon-after>
                                    <button type="button" @click="show_password = !show_password" class="addon-button">
                                        <!-- <img v-if="!isDark" src='@/assets/images/view-icon.png' />
                        <img v-else src='@/assets/images/view-icon-dark.png' /> -->
                                        <font-awesome-icon v-if="!show_password" icon="fa-solid fa-eye" size="sm"
                                            style="color: #9ca3af;" />
                                        <font-awesome-icon v-else icon="fa-solid fa-eye-slash" size="sm"
                                            style="color: #9ca3af;" />
                                    </button>
                                </template>
                            </TextElement>


                            <ButtonElement name="button" :submits="true" full :loading="is_loading"
                                button-class="bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg text-center !border-gray-100 !text-gray-800 text-md font-medium leading-normal ">
                                {{ $t("btn_submit") }}
                            </ButtonElement>


                        </Vueform>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
// import VuePdfApp from "vue3-pdf-app";
// // import this to use default icons for buttons
// import "vue3-pdf-app/dist/icons/main.css";




export default {
    name: 'ProtostarsEY',
    // components: { VuePdfApp },
    data() {
        return {
            has_access: false,
            password: null,
            password_txt: "Protostar$",
            error_message: null,
            formData: {
                password: null
            },
            show_password: false,

            file_path: new URL('@/assets/pdf/protostars_presentation.pdf', import.meta.url).href
        }
    },
    mounted(){
        console.log("mmmm")
        // console.log( new URL('@/assets/pdf/protostars_presentation.pdf'))
        // console.log( new URL('@/assets/pdf/protostars_presentation.pdf').href)
        // console.log( new URL('@/assets/pdf/protostars_presentation.pdf', import.meta.url).href)
        // console.log( new URL('@/assets/pdf/protostars_presentation.pdf', import.meta.url))
    },
    methods: {
        checkPassword() {
            if (this.formData.password == null || this.formData.password == '') {
                this.error_message = "Please enter password";
                return;
            }
            if (this.password_txt == this.formData.password) {
                this.error_message = null
                this.has_access = true;
            }
            else {
                this.error_message = "Invalid Password";
            }
        },
        clickkk(){
            console.log("clickkk")
        }

    }
}
</script>