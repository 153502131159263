<template>
    <section>
        <div class="relative max-w-6xl mx-auto  md:px-14  px-2 py-12 md:py-20">
            <div class="w-full mx-auto mb-4 text-center">
                <h2 class="lg:text-5xl md:text-2xl/normal text-xl text-slate-50 font-extrabold mb-8">{{ $t('how_it_works_title') }}</h2>
                <p class="text-lg/7 text-slate-400 font-normal">{{ $t('how_it_works_subtitle') }}</p>
            </div>
            <div class="w-full mx-auto tabs-container">

                <flowbite-themable>
                    <fwb-tabs v-model="activeTab" variant="underline" class="">
                        <fwb-progress :progress="progress_percentage" class="progress my-3 w-full" size="sm" />
                        <fwb-tab :name="1" :title="$t('step_1_title')">
                           <!-- {{$t('step_1_title')}} -->
                           <div class="flex flex-wrap w-100 lg:px-12 md:px-5 px-3 py-8 items-center  md:text-left text-center">
                                <div class="lg:w-1/2 md:w-2/3 w-full md:ps-4 md:pe-10 px-2 md:mb-0 mb-5">
                                    <h3 class="text-3xl/10 text-white font-bold mb-4">{{ $t('step_1_title') }}</h3>
                                    <p class="text-lg/normal text-slate-400 font-normal">{{ $t('step_1_description')}}</p>
                                </div>
                                <div class="lg:w-1/2 md:w-1/3 w-full px-4">
                                    <img src="@/assets/images/how_it_works_step_1.png" alt="step1" class="w-full xl:max-w-xs 2xl:max-w-sm lg:max-w-sm m-auto" />
                                </div>
                           </div>
                        </fwb-tab>
                        <fwb-tab :name="2" :title="$t('step_2_title')">
                           <div class="flex flex-wrap w-100  lg:px-12 md:px-5 px-3 py-8 items-center md:text-left text-center">
                                <div class="lg:w-1/2 md:w-2/3 w-full md:ps-4 md:pe-10 px-2 md:mb-0 mb-5">
                                    <h3 class="text-3xl/10 text-white font-bold mb-4">{{ $t('step_2_title') }}</h3>
                                    <p class="text-lg/normal text-slate-400 font-normal">{{ $t('step_2_description')}}</p>
                                </div>
                                <div class="lg:w-1/2 md:w-1/3 w-full px-4">
                                    <img src="@/assets/images/how_it_works_step_2.png" alt="step2" class="w-full  m-auto" />
                                </div>
                           </div>                            
                        </fwb-tab>
                        <fwb-tab :name="3" :title="$t('step_3_title')">
                            <div class="flex flex-wrap w-100  lg:px-12 md:px-5 px-3 py-8 items-center  md:text-left text-center">
                                  <div class="lg:w-1/2 md:w-2/3 w-full md:ps-4 md:pe-10 px-2 md:mb-0 mb-5">
                                        <h3 class="text-3xl/10 text-white font-bold mb-4">{{ $t('step_3_title') }}</h3>
                                        <p class="text-lg/normal text-slate-400 font-normal">{{ $t('step_3_description')}}</p>
                                  </div>
                                  <div class="lg:w-1/2 md:w-1/3 w-full px-4">
                                        <img src="@/assets/images/how_it_works_step_3.png" alt="step3" class="w-full xl:max-w-md 2xl:max-w-lg lg:max-w-sm m-auto" />
                                  </div>
                            </div>
                        </fwb-tab>
                        <fwb-tab :name="4" :title="$t('step_4_title')">
                            <div class="flex flex-wrap w-100  lg:px-12 md:px-5 px-3 py-8 items-center  md:text-left text-center">
                                  <div class="lg:w-1/2 md:w-2/3 w-full md:ps-4 md:pe-10 px-2 md:mb-0 mb-5">
                                        <h3 class="text-3xl/10 text-white font-bold mb-4">{{ $t('step_4_title_content') }}</h3>
                                        <p class="text-lg/normal text-slate-400 font-normal">{{ $t('step_4_description')}}</p>
                                  </div>
                                  <div class="lg:w-1/2 md:w-1/3 w-full ps-4 pe-1">
                                        <img src="@/assets/images/how_it_works_step_4.png" alt="step4" class="w-full  m-auto" />
                                  </div>
                            </div>
                        </fwb-tab>
                        <fwb-tab :name="5" :title="$t('step_5_title')">
                            <div class="flex flex-wrap w-100  lg:px-12 md:px-5 px-3 py-8 items-center  md:text-left text-center">
                                  <div class="lg:w-1/2 md:w-2/3 w-full md:ps-4 md:pe-10 px-2 md:mb-0 mb-5">
                                        <h3 class="text-3xl/10 text-white font-bold mb-4">{{ $t('step_5_title_content') }}</h3>
                                        <p class="text-lg/normal text-slate-400 font-normal">{{ $t('step_5_description')}}</p>
                                  </div>
                                  <div class="lg:w-1/2 md:w-1/3 w-full ps-4 pe-1">
                                        <img src="@/assets/images/how_it_works_step_5.png" alt="step5" class="w-full 2xl:max-w-lg lg:max-w-md  m-auto" />
                                  </div>
                            </div>
                        </fwb-tab>
                    </fwb-tabs>
                </flowbite-themable>
            </div>
        </div>
    </section>

</template>
<script>

import { initFlowbite } from 'flowbite'
import { FwbTab, FwbTabs, FlowbiteThemable } from 'flowbite-vue'
import { FwbProgress } from 'flowbite-vue'


export default {
    name: 'HowItWorksSection',
    components: {
        FwbTab, FwbTabs, FlowbiteThemable, FwbProgress
    },
    data() {
        return {
            activeTab: 1,
        }
    },
    computed:{
        progress_percentage(){
            return (this.activeTab/5) * 100
        }
    }
}
</script>
<style >
.progress > div{
    @apply h-px relative;
}
.progress > div > div{
    @apply bg-gradient-to-r from-[#55c6f7] to-[#0F172A4F] rounded-md h-[9px] absolute -top-1;
}

.tabs-container > div{
    @apply border-none ;
}
.tabs-container > div > ul{
    @apply flex flex-nowrap justify-between -mb-px text-sm font-medium text-center;
}
.tabs-container > div > ul li{
    /* @apply w-full; */
}
.tabs-container > div > ul li div{
    @apply w-full inline-block border-none px-0 sm:text-lg/normal text-[10px]/normal font-semibold text-white; 
}
.tabs-container > div > ul li div:hover{
    @apply border-none text-blue-300;
}
.tabs-container > div > ul li div.active{
    @apply bg-transparent border-none text-[#60a5fa];
}
</style>