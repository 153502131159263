<template>
  <section class="2xl:h-auto lg:h-screen h-auto relative">
    <div class="relative max-w-6xl mx-auto 2xl:h-[50vh] h-auto min-h-screen">

      <!-- Particles animation -->
      <ParticlesComponent class="absolute inset-0 -z-10" />

      <!-- Illustration -->
      <div class="absolute inset-0 -z-10 -mx-28 rounded-b-[3rem] pointer-events-none overflow-hidden"
        aria-hidden="true">
        <div class="absolute left-1/2 -translate-x-1/2 bottom-0 -z-10">
          <img src="@/assets/images/stellar/glow-bottom-blue.svg" class="max-w-none" width="2146" height="774"
            alt="Hero Illustration">
        </div>
      </div>

      <div class="relative w-full mx-0 md:h-screen h-auto min-h-screen lg:flex lg:items-center"> <!--md:h-screen-->
        <!-- slides -->
        <!-- item 1 -->
        <div
          :class="['md:absolute relative overflow-visible inset-0 w-full h-auto transition-opacity duration-[2000ms] slide-content-container', currentIndex === 0 ? 'opacity-100 z-50' : 'opacity-0 -z-50']">

          <!-- <img src="/docs/images/carousel/carousel-2.svg"
              class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."> -->
          <div
            class="  pt-12 pb-12 md:pt-20 md:pb-20 lg:px-8 md:px-5 px-2 lg:h-full h-auto 2xl:max-h-fit lg:max-h-full max-h-fit flex items-center">

            <div class="w-full flex flex-wrap  items-center lg:max-h-full lg:h-full xl:mt-10 lg:mt-12 mt-4">
              <!-- Hero content -->
              <div class="w-full md:w-full lg:w-[45%] xl:w-2/5 mx-auto px-2 md:px-0">
                <h1
                  class="xl:text-4xl/tight  md:text-3xl/normal text-2xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-white/60 via-slate-200 to-slate-200/60 lg:mb-7 mb-5 lg:mt-0 mt-4"
                  data-aos="fade-down">{{ $t('cyber_grants_header') }}</h1>
                <p class="md:text-lg/7 text-base/6 font-normal text-slate-300 md:mb-8 mb-4" data-aos="fade-down"
                  data-aos-delay="200">
                  <span class="w-[862px] text-center" v-html="$t('hero_grant_text')"></span>
                </p>

                <div class="mb-6 md:flex md:gap-6  md:text-left text-center" data-aos="fade-down">
                  <div
                    class="inline-flex relative before:absolute before:inset-0 before:bg-cyan-500 before:blur-md mb-2">
                    <a class="min-w-48 w-auto btn-sm h-9 py-0.5 text-slate-300 hover:text-white hover:no-underline transition duration-150 ease-in-out group [background:linear-gradient(theme(colors.slate.500),_theme(colors.slate.500))_padding-box,_linear-gradient(theme(colors.cyan.500),_theme(colors.cyan.200)_75%,_theme(colors.transparent)_100%)_border-box]  relative before:absolute before:inset-0 before:bg-slate-800/50 before:rounded-full before:pointer-events-none shadow"
                      href="/cyber-grants">
                      <span class="relative inline-flex items-center">
                        {{ $t('header_cyber_grants_btn') }} <span
                          class="tracking-normal text-[#55EDF7] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"><font-awesome-icon
                            icon="fa-solid fa-arrow-right" /></span>
                      </span>
                    </a>

                  </div>

                </div>
              </div>
              <div class="w-full md:w-full lg:w-[55%] xl:w-3/5 lg:h-full h-auto relative lg:pl-12">
                <!-- <img src="@/assets/images/hero-img.png" class="w-full img-zoom" alt="Hero Illustration"> -->
                <div
                  class="container flex md:flex-row flex-col  justify-between items-center   w-full  md:h-full  gap-2 md:overflow-visible overflow-auto">
                  <img src="@/assets/images/hero-grant-card-1.png"
                    class="block  xl:w-[45%]  lg:w-[45%] md:w-[45%] w-10/12 mx-auto xl:h-9/12 md:h-auto h-full z-1000"
                    alt="Hero Illustration">
                  <img src="@/assets/images/request-call/arrow-right.png"
                    class="md:block hidden xl:w-11 w-10   z-1000"
                    alt="Hero Illustration">
                  <img src="@/assets/images/hero-grant-card-2.png"
                    class="block   xl:w-[45%] lg:w-[45%] md:w-[45%] w-10/12 mx-auto xl:h-9/12 md:h-auto h-full z-1000"
                    alt="Hero Illustration">

                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- item 2 -->
        <div
          :class="['absolute inset-0 w-full mx-0 h-full transition-opacity duration-[2000ms] slide-content-container', currentIndex === 1 ? 'opacity-100 z-50' : 'opacity-0 -z-50']">
          <div
            class="  pt-12 pb-12 md:pt-20 md:pb-20 lg:px-8 md:px-3 px-2 h-full 2xl:max-h-fit lg:max-h-full max-h-fit flex items-center">

            <div class="w-full flex flex-wrap items-center max-h-full h-full xl:mt-8 lg:mt-10 mt-4 ">
              <!-- Hero content -->
              <div class="w-full md:w-7/12 lg:w-1/2 mx-auto px-4 md:px-0">
                <h1
                  class="xl:text-4xl/tight  md:text-3xl/normal text-2xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-white/60 via-slate-200 to-slate-200/60 lg:mb-7 md:mb-5 mb-3"
                  data-aos="fade-down">{{ $t('hero_title') }}</h1>
                <!-- <h2
                    class="xl:text-[32px]/normal  md:text-[28px]/normal text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white/60 via-slate-200 to-slate-200/60 mb-4"
                    data-aos="fade-down" v-html="$t('hero_subtitle')"></h2> -->
                <p class="md:text-lg/7 text-base/6 font-normal text-slate-300 md:mb-8 mb-4" data-aos="fade-down"
                  data-aos-delay="200">
                  <span class="w-[862px] text-center" v-html="$t('hero_text')"></span>
                </p>

                <div class="mb-6 md:flex md:gap-6  md:text-left text-center" data-aos="fade-down">
                  <div
                    class="inline-flex relative before:absolute before:inset-0 before:bg-cyan-500 before:blur-md mb-2 ">
                    <a class="min-w-48 w-auto btn-sm h-9 py-0.5 text-slate-300 hover:text-white hover:no-underline transition duration-150 ease-in-out group [background:linear-gradient(theme(colors.slate.500),_theme(colors.slate.500))_padding-box,_linear-gradient(theme(colors.cyan.500),_theme(colors.cyan.200)_75%,_theme(colors.transparent)_100%)_border-box]  relative before:absolute before:inset-0 before:bg-slate-800/50 before:rounded-full before:pointer-events-none shadow"
                      href="/early-invite">
                      <span class="relative inline-flex items-center">
                        {{ $t('early_wishlist_title') }} <span
                          class="tracking-normal text-[#55EDF7] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"><font-awesome-icon
                            icon="fa-solid fa-arrow-right" /></span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              
              <div class="w-full md:w-5/12 lg:w-1/2 max-h-full md:pl-12">
                <img src="@/assets/images/hero-img.png" class="w-full " alt="Hero Illustration">
              </div>
            </div>
          </div>
        </div>

        <!-- Indicators -->
        <div class="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 z-999">
          <span @click="setSlide(0)" class="cursor-pointer block w-3 h-3 rounded-full"
            :class="currentIndex === 0 ? 'bg-white' : 'bg-gray-400'"></span>
          <span @click="setSlide(1)" class="cursor-pointer block w-3 h-3 rounded-full"
            :class="currentIndex === 1 ? 'bg-white' : 'bg-gray-400'"></span>
        </div>
      </div>





    </div>
  </section>
</template>

<script>
import ParticlesComponent from './ParticlesComponent.vue'

export default {
  name: 'HeroSection',
  components: {
    ParticlesComponent
  }, data() {
    return {
      slider_no: 2,
      currentIndex: 0,
      interval: null,
    };
  },
  methods: {
    // Function to show the next slide
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.slider_no;
    },

    // Function to show the previous slide
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.slider_no) % this.slider_no;
    },

    // Function to set the slide based on indicator click
    setSlide(index) {
      this.currentIndex = index;
    },
  },
  mounted() {
    // Autoplay: Automatically change slides every 3 seconds
    this.interval = setInterval(this.nextSlide, 5500);
  },
  beforeUnmount() {
    // Cleanup the interval when component is destroyed
    clearInterval(this.interval);
  },
}
</script>

<style scoped>
/* .container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: auto;
  padding: 20px;
  transition: all 0.3s ease;
} */


.background-gredient {
  background: linear-gradient(-45deg, #f9d6495a, #74FAB573, transparent);
  background-size: 400% 400%;
  animation: gradient 15s ease-in-out infinite;
  /* height: 100vh; */
}

@keyframes gradient {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 91% 100%;
  }

  100% {
    background-position: 10% 0%;
  }
}

.img-zoom,
.container {
  transition: transform 1.5s ease;
}

.img-zoom:hover {
  transform: scale(1.1);
  /* Zoom in by 20% */
}

.box {
  /* width: 100px;
  height: 100px; */
  /* background-color: lightblue; */
  /* transition: all 0.3s ease; */
  transition: all .2s ease-in-out;
}

@media screen and (min-width: 1024px) {

  /* Hover on container to enlarge one box and shrink the other */
  .container:hover .box1 {
    transform: scale(0.8);
    /* Enlarges box1 */
  }

  .container:hover .box2 {
    transform: scale(1.2);
    /* Shrinks box2 */
  }
}


/* @keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
} */

/* @keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
} */
</style>