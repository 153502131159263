import en from '@vueform/vueform/locales/en'
import tailwind from '@vueform/vueform/dist/tailwind'
import { defineConfig } from '@vueform/vueform'

export default defineConfig({
    theme: tailwind,
    locales: { en },
    locale: 'en',


    // removeClasses: {
    //     TextElement: {
    //         // input: ["with-floating:form-p-input-floating "],
    //         // inputContainer: ["form-radius-large with-floating:form-p-input-floating form-bg-input-success form-color-input-success form-border-color-input-success hover:form-shadow-input-hover focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hover"]
    //         inputContainer: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
    //           "focused:form-shadow-input-focus", "focused:form-ring",
    //           "focused-hover:form-shadow-input-hover"],
    //         inputContainer_focused: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
    //             "focused:form-shadow-input-focus", "focused:form-ring",
    //             "focused-hover:form-shadow-input-hover"],
    //         // input_focused: ["form-color-input-focus", "form-autofill-focus"]

    //     },
    //     TextareaElement: {
    //         // input: ["with-floating:form-p-input-floating "],
    //         // inputContainer: ["form-radius-large", "with-floating:form-p-input-floating", "form-bg-input-success", "form-color-input-success", "form-border-color-input-success", "fhover:form-shadow-input-hover", "ffocused:form-shadow-input-focus", "ffocused:form-ring", "ffocused-hover:form-shadow-input-hover"]
    //         // inputContainer: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
    //         //   "focused:form-shadow-input-focus", "focused:form-ring",
    //         //   "focused-hover:form-shadow-input-hover"],
    //         // inputContainer_focused: ["form-bg-input-focus", "form-color-input-focus", "form-border-color-input-focus",
    //         //   "form-shadow-input-focus", "form-ring",
    //         //   "form-shadow-input-hover"],
    //         // input_focused: ["form-color-input-focus", "form-autofill-focus"]

    //     },
    // }



    replaceClasses: {
        TextElement: {
            input_md: {
                "form-text": "text-sm/normal text-slate-300"
            },
            input_enabled: {
                "form-color-input": "text-slate-300"
            },
            inputContainer_danger: {
                "form-bg-input-danger": "bg-gray-900/60",
                "form-border-color-input-danger": "border-[#CBD5E1]",

            }
        },

        CheckboxElement: {
            input: {
                'form-bg-checkbox': 'bg-gray-900/60 dark:bg-gray-900/60 ',
                'form-border-color-checkbox': 'border-[#CBD5E1] dark:border-[#CBD5E1]'
            },
            input_default: {
                // bg-gray-900/60 dark:bg-gray-900/60  
                'form-bg-checkbox': 'bg-gray-900/60 dark:bg-gray-900/60 ',
                'form-border-color-checkbox': 'border-[#CBD5E1] dark:border-[#CBD5E1]',
                'checked:form-bg-primary': 'checked:bg-white dark:checked:bg-primary-400',
                'checked:form-border-color-checked': 'checked:border-primary dark:checked:border-primary-400'
            },
            input_md: {
                'form-mt-checkbox': 'my-auto',
            }
        },

        ElementLabelFloating: {
            label_focused: {
                'form-color-floating-focus': "text-slate-400"
            },
            label_success: {
                "form-color-floating-success": "text-slate-400"
            }

        },


        SelectElement: {
            inputPlaceholder: {
                'form-color-placeholder': 'text-slate-300'
            },
            select: {
                placeholder: {
                    'form-color-placeholder': 'text-slate-300',
                },
                options: {

                    "form-color-input": "text-slate-300"
                }
            }
            //  select: {
            //     container: {
            //       'form-border-width-input': '',
            //       'form-bg-input':'bg-blue-100',
            //       "form-border-color-input":"border-green-500"
            //     }
            //   },
            // SelectElement: {
            //   container: {
            //     'form-border-width-input': '',
            //     'form-bg-input': '',
            //     'form-border-color-input': '',
            //     "hover:form-bg-input-hover": '',
            //     "hover:form-color-input-hover": '',
            //     "hover:form-border-color-input-hover": '',
            //     "hover:form-shadow-input-hover": ''
            //   },
            //   container_default: {
            //     'form-border-width-input': '',
            //     'form-bg-input': '',
            //     'form-border-color-input': '',
            //     "hover:form-bg-input-hover": '',
            //     "hover:form-color-input-hover": '',
            //     "hover:form-border-color-input-hover": '',
            //     "hover:form-shadow-input-hover": ''
            //   }
            // },

        },
        ElementLabel: {
            container_vertical_md: {
                'form-pb-gutter/3': 'pb-px',
                // 'form-pt-0': 'mt-2'
            },
        },

    },
    removeClasses: {
        ElementLabel: {
            container: ['form-text'],
        },
        TextElement: {
            container: ['form-text'],
            input: ["with-floating:form-p-input-floating "],
            inputContainer: ["form-radius-large with-floating:form-p-input-floating form-bg-input-success form-color-input-success form-border-color-input-success hover:form-shadow-input-hover focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hover"]
            // inputContainer: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
            //   "focused:form-shadow-input-focus", "focused:form-ring",
            //   "focused-hover:form-shadow-input-hover"],
            // inputContainer_focused: ["form-bg-input-focus", "form-color-input-focus", "form-border-color-input-focus",
            //   "form-shadow-input-focus", "form-ring",
            //   "form-shadow-input-hover"],
            // input_focused: ["form-color-input-focus", "form-autofill-focus"]

        },
        TextareaElement: {
            input: ["with-floating:form-p-input-floating "],
            inputContainer: ["form-radius-large", "with-floating:form-p-input-floating", "form-bg-input-success", "form-color-input-success", "form-border-color-input-success", "fhover:form-shadow-input-hover", "focused:form-shadow-input-focus", "focused:form-ring", "focused-hover:form-shadow-input-hover"]
            // inputContainer: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
            //   "focused:form-shadow-input-focus", "focused:form-ring",
            //   "focused-hover:form-shadow-input-hover"],
            // inputContainer_focused: ["form-bg-input-focus", "form-color-input-focus", "form-border-color-input-focus",
            //   "form-shadow-input-focus", "form-ring",
            //   "form-shadow-input-hover"],
            // input_focused: ["form-color-input-focus", "form-autofill-focus"]

        },
        SelectElement: {
            input: ["with-floating:form-p-input-floating", "form-bg-input", "form-color-input", "form-border-color-input", "focused:form-ring"],
            //   inputContainer: ["form-radius-large", "fwith-floating:form-p-input-floating", "form-bg-input-success", "form-color-input-success", "form-border-color-input-success", "fhover:form-shadow-input-hover", "focused:form-shadow-input-focus", "focused:form-ring", "focused-hover:form-shadow-input-hover"],
            //   // container:["form-bg-input","form-color-input","form-border-color-input"
            //   // ,"hover:form-bg-input-hover","hover:form-color-input-hover","hover:form-border-color-input-hover",
            //   // "hover:form-shadow-input-hover", "focused:form-bg-input-focus","focused:form-color-input-focus",
            //   // "focused:form-border-color-input-focus","focused:form-shadow-input-focus","focused:form-ring",
            //   //  "focused-hover:form-shadow-input-hover"],
            SelectElement: {
                container: ["form-bg-input", "form-color-input",
                    "form-border-color-input", "form-border-width-input", "hover:form-bg-input-hover", "hover:form-color-input-hover", "hover:form-border-color-input-hover",
                    "hover:form-shadow-input-hover", "focused:form-ring"],
                container_default: ["form-bg-input", "form-color-input",
                    "form-border-color-input", "form-border-width-input", "hover:form-bg-input-hover", "hover:form-color-input-hover", "hover:form-border-color-input-hover",
                    "hover:form-shadow-input-hover", "focused:form-ring"]
                //     ,"hover:form-bg-input-hover","hover:form-color-input-hover","hover:form-border-color-input-hover",
                //     "hover:form-shadow-input-hover", "focused:form-bg-input-focus","focused:form-color-input-focus",
                //     "focused:form-border-color-input-focus","focused:form-shadow-input-focus","focused:form-ring",
                //      "focused-hover:form-shadow-input-hover"]
            },
            select: {
                container: ["form-bg-input", "form-color-input",
                    "form-border-color-input", "form-border-width-input", "hover:form-bg-input-hover", "hover:form-color-input-hover", "hover:form-border-color-input-hover",
                    "hover:form-shadow-input-hover", "focused:form-ring"],
                container_default: ["form-bg-input", "form-color-input",
                    "form-border-color-input", "form-border-width-input", "hover:form-bg-input-hover", "hover:form-color-input-hover", "hover:form-border-color-input-hover",
                    "hover:form-shadow-input-hover", "focused:form-ring"]
                //     ,"hover:form-bg-input-hover","hover:form-color-input-hover","hover:form-border-color-input-hover",
                //     "hover:form-shadow-input-hover", "focused:form-bg-input-focus","focused:form-color-input-focus",
                //     "focused:form-border-color-input-focus","focused:form-shadow-input-focus","focused:form-ring",
                //      "focused-hover:form-shadow-input-hover"]
            },
            //   // inputContainer: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
            //   //   "focused:form-shadow-input-focus", "focused:form-ring",
            //   //   "focused-hover:form-shadow-input-hover"],
            //   // inputContainer_focused: ["form-bg-input-focus", "form-color-input-focus", "form-border-color-input-focus",
            //   //   "form-shadow-input-focus", "form-ring",
            //   //   "form-shadow-input-hover"],
            //   // input_focused: ["form-color-input-focus", "form-autofill-focus"]

            // },
        },


        ButtonElement: {
            button: ["form-border-width-btn", "form-bg-btn", "form-color-btn", "form-border-color-btn"],
            button_primary: ["form-bg-btn", "form-color-btn", "form-border-color-btn"],
            button_enabled: ["hover:scale-105"],
        },

    },
    addClasses: {
        TextElement: {
            input: ['placeholder:text-slate-300 ']
        },
        ButtonElement: {
            // button: ["form-border-width-btn", "form-bg-btn", "form-color-btn", "form-border-color-btn"],
            button_primary: ["bg-primary", "text-white"]
        },
        CheckboxElement: {
            text: 'text-sm font-normal font-Poppins text-gray-900/60-700 dark:text-white'
        },
    },
    overrideClasses: {
        TextElement: {
            inputContainer: 'rounded-lg w-full flex border-[0.5px] border-[#CBD5E1] dark:border-[#CBD5E1] bg-gray-900/60 dark:bg-gray-900/60   dark:text-slate-300  ',
            inputContainer_default: 'border-[0.5px] border-[#CBD5E1] dark:border-[#CBD5E1] bg-gray-900/60 dark:bg-gray-900/60 text-sm',
            inputContainer_focused: 'border-[0.5px] border-[#CBD5E1] border-b-2 border-b-[#338CF5] dark:border-b-[#338CF5] bg-gray-900/60  rounded-none ',
            inputContainer_success: '',
            //   inputContainer_disabled: ["bg-[#EFEFF2] border-[#DADAE0] dark:bg-[#394560] dark:border-[#8A8A95]"],
            input_focused: 'rounded-none border-none border-b-blue-400',
            input_success: '',
            // input_disabled: "opacity-50"

        },
        TextareaElement: {
            inputContainer: 'border-[0.5px] border-[#CBD5E1] bg-gray-900/60 rounded-lg w-full flex dark:bg-gray-900/60 dark:border-[#CBD5E1] dark:text-slate-300  disabled:bg-[#EFEFF2] disabled:border-[#DADAE0] dark:disabled:bg-[#bfc7d9] dark:disabled:border-[#8A8A95]',
            inputContainer_default: 'border-[#CBD5E1] bg-gray-900/60 ',
            inputContainer_focused: 'border-[0.5px] border-[#CBD5E1] border-b-2 border-b-[#338CF5] bg-gray-900/60  rounded-none dark:border-b-[#338CF5]',
            inputContainer_success: '',
            //   inputContainer_disabled: ["bg-[#EFEFF2] border-[#DADAE0] dark:bg-[#242A36] dark:border-[#6F6F7A]"],
            // input:'border-none',
            input_focused: 'rounded-none border-none',
            input_success: '',
            input_disabled: ['text-[#8F9099]']
        },
        SelectElement: {

            // input_focused: 'rounded-none border-none',//may needed later
            //   inputContainer: 'border border-[#CBD5E1] bg-gray-900/60 rounded w-full flex dark:bg-gray-900/60 dark:border-[#CBD5E1] dark:text-gray-900/60  ',
            //   inputContainer_default: 'border-[#CBD5E1] bg-gray-900/60 dark:bg-gray-900/60',
            //   inputContainer_focused: 'border border-[#CBD5E1] border-b-2 border-b-[#338CF5] bg-gray-900/60  rounded-none dark:border-b-[#338CF5]',
            //   inputContainer_success: '',
            //   input_success: '',// inputContainer_success: '',
            input: 'border-[0.5px] border-[#CBD5E1]  bg-gray-900/60 rounded-lg w-full flex dark:bg-gray-900/60 dark:border-[#CBD5E1]  text-slate-300 dark:text-slate-300  focus:rounded-none focus:border-none placeholder:text-slate-300',
            input_default: 'border-[0.5px] border-[#CBD5E1]  bg-gray-900/60 rounded-lg w-full flex dark:bg-gray-900/60 dark:border-[#CBD5E1]  text-slate-300 dark:text-slate-300  focus:rounded-none focus:border-none  placeholder:text-slate-300',
            //   // input_success: '',
            //   // select:{
            //   //   container: 'border border-[#CBD5E1] bg-gray-900/60 rounded w-full flex dark:bg-gray-900/60 dark:border-[#CBD5E1] dark:text-gray-900/60  ',
            //   // }
            select: {
                container: 'relative mx-auto w-full flex justify-end items-center cursor-pointer transition-input duration-200 form-min-h-input-height border-[0.5px] border-[#CBD5E1]  dark:border-[#CBD5E1]  bg-gray-900/60 dark:bg-gray-900/60 rounded w-full flex  dark:text-slate-300  focus:rounded-none focus:border-none',
                container_default: ' rounded-lg w-full flex border-[0.5px] border-[#CBD5E1]  dark:border-[#CBD5E1]  bg-gray-900/60 dark:bg-gray-900/60  dark:text-slate-300   focus:rounded-none focus:border-none',
                container_success: ' rounded-lg w-full flex border-[0.5px] border-[#CBD5E1]  dark:border-[#CBD5E1]  bg-gray-900/60 dark:bg-gray-900/60  dark:text-slate-300   focus:rounded-none focus:border-none',
                dropdown: "max-h-60 absolute z-999 -left-px -right-px bottom-0 transform shadow-md form-border-width-dropdown border-solid border-[#CBD5E1]  dark:border-[#CBD5E1]  bg-gray-900 dark:bg-gray-900 -mt-px overflow-y-scroll flex flex-col",
                optionSelected: "bg-[#338CF5]/50 text-slate-300",
                optionPointed: "bg-slate-protostars-200 dark:bg-gray-protostars-900 text-gray-protostars-900 dark:text-white ",
                optionSelectedPointed: "bg-[#338CF5]/50 text-gray-900/60",
                noOptions: "text-slate-300",
                noResults: "text-slate-300",
            }

        },
        ElementLabel: {
            container: ['text-lg font-normal font-Inter text-primary-900 dark:text-white']
        },

    },
})