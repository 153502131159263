<template>
    <section>
        <div class="relative max-w-6xl mx-auto px-4 sm:px-6">

            <!-- Particles animation -->
            <ParticlesComponent class="absolute inset-0 -z-10" />

            <!-- Illustration -->
            <div class="absolute inset-0 -z-10 -mx-28 rounded-3xl pointer-events-none overflow-hidden"
                aria-hidden="true">
                <div class="absolute left-[40%] -translate-x-1/2 top-0 -z-10">
                    <img src="@/assets/images/stellar/glow-top-bottom.svg" class="max-w-none w-[360px] h-[360px]"
                        alt="Hero Illustration">
                </div>
            </div>

            <div class="pt-12 pb-16 md:pt-24 md:pb-12">
                <div class="">
                    <slot></slot>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ParticlesComponent from './ParticlesComponent.vue'

export default {
    name: 'HeroSection',
    components: {
        ParticlesComponent
    }
}
</script>