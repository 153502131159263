import { createApp } from 'vue'
import { createI18n } from 'vue-i18n';
// import VueI18n from 'vue-i18n'


import App from './App.vue'
import router from './router'

import VueAxios from 'vue-axios'
import axios from 'axios'

// import { BootstrapVue } from 'bootstrap-vue'

// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)



// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/css/styles.css'
// import './assets/css/auth-styles.css'


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight, faAt, faBars, faCheck, faChevronDown, faCircleCheck, faEye, faEyeSlash, faLocationDot, faMobileScreen } from '@fortawesome/free-solid-svg-icons'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'
library.add({ faArrowRight, faAt, faBars, faCheck, faChevronDown, faCircleCheck, faCopyright, faEye, faEyeSlash, faLocationDot, faMobileScreen });

import Vue3Marquee from 'vue3-marquee'

import 'aos/dist/aos.css'

axios.defaults.withCredentials = false
// axios.defaults.baseURL = 'https://microservices-staging.protostars.ai/api/'
axios.defaults.baseURL = 'https://microservices.protostars.ai/api/'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

import messages from './assets/languages'

export const i18n = createI18n({
  //legacy: false, // you must set `false`, to use Composition API
  // locale: 'en',
  locale: navigator.language, 
  fallbackLocale: 'en',
  messages: messages,
  // globalInjection: true
});

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";


// const i18n = new VueI18n({
//   locale: 'en', // set locale
//   fallbackLocale: 'en', // set fallback locale
//   messages, // set locale messages
// })


import Vueform from '@vueform/vueform'
import vueformConfig from '../vueform.config'


// import VueCookieComply from 'vue-cookie-comply'
// import 'vue-cookie-comply/dist/style.css'
import VueCookieComply from '@ipaat/vue3-tailwind3-cookie-comply'

createApp(App).use(router).use(VueAxios, axios)
  .use(VueSweetalert2).use(Vue3Marquee)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(i18n)
  .component("v-select", vSelect)
  .use(Vueform, vueformConfig)
  // .use(VueCookieComply)
  .component('VueCookieComply', VueCookieComply)
  .mount('#app')
